import { v4 as uuidv4 } from 'uuid'
import * as z from 'zod'

import { GazelleRef } from '@apsys/gazelle'
import { Point as IMDFPoint } from '@apsys/imdf'

import type { ChosenExperience } from '../context/AppContext'
import { SEARCH_PARAMS, LOCAL_STORAGE } from '../enums'
import { EileenColorScheme } from '../schemas/eileen-service-stylesheet'
import { Audience } from '../schemas/vixen-core-manifest'
import {
  FeatureRef,
  FeatureRefSchema,
  GeojsonPoint,
  GeojsonPointSchema,
  IGeojsonPoint,
  ILevelRef,
  IVenueRef,
  LevelRef,
  LevelRefSchema,
} from '../schemas/vixen-spatial'
import { isToday } from '../utils/dateTime'
import { debugError, debugLog } from '../utils/debug'

/**
 * Clears experience from local storage.
 */
export const resetExperience = (): void => {
  localStorage.removeItem(LOCAL_STORAGE.EXPERIENCE)
  localStorage.removeItem(LOCAL_STORAGE.ESTIMATED_LOCATION)
  localStorage.removeItem(LOCAL_STORAGE.LATEST_ONBOARDING_TIME)
}

/**
 * Set the currently selected experience, either as a GazelleRef or as the
 * special experience "random-access"
 *
 * @internal do this via {@link AppContext}
 */
export const setExperience = (ref: ChosenExperience): void =>
  localStorage.setItem(LOCAL_STORAGE.EXPERIENCE, JSON.stringify(ref))

/**
 * Get the currently selected experience, either as a GazelleRef or as the
 * special experience "random-access" from localstorage.
 *
 * @internal do this value from {@link AppContext} or better, use
 * {@link useOnboarded} if you want to check the selected tour is actually
 * valid.
 */
export const getExperience = (): ChosenExperience => {
  try {
    const rawValue = localStorage.getItem(LOCAL_STORAGE.EXPERIENCE)
    if (!rawValue) return null

    const value = JSON.parse(rawValue)
    if (value === 'random-access') return value

    return GazelleRef.parse(value)
  } catch (exc) {
    return null
  }
}

/**
 * Set the theme variant in local storage.
 * @internal Do this via {@link AppContext}.
 */
export const setThemeVariant = (
  theme: EileenColorScheme.Mode | null,
): void => {
  if (theme !== null) {
    localStorage.setItem(LOCAL_STORAGE.THEME_VARIANT, theme)
  } else {
    localStorage.removeItem(LOCAL_STORAGE.THEME_VARIANT)
  }
}

const THEME_VARIANT_SCHEMA = z.nativeEnum(EileenColorScheme.Mode).nullable()

/**
 * Retrieve the theme variant from localstorage.
 * @internal this value from {@link AppContext}.
 */
export const getThemeVariant = (): EileenColorScheme.Mode | null => {
  const value = THEME_VARIANT_SCHEMA.safeParse(
    localStorage.getItem(LOCAL_STORAGE.THEME_VARIANT),
  )
  if (value.success) return value.data
  return null
}

/** Validate the deviceId value */
const DEVICE_ID_SCHEMA = z.string().uuid()

/**
 * Sets the deviceID to a new random UUID and returns it.
 *
 * @internal -- only called by startup or by the device getter
 */
export const setDeviceID = (): void => {
  const deviceId = localStorage.getItem(LOCAL_STORAGE.DEVICE_ID)
  if (!DEVICE_ID_SCHEMA.safeParse(deviceId).success) {
    localStorage.setItem(LOCAL_STORAGE.DEVICE_ID, uuidv4())
  }
}

/**
 * Get the current device ID. Used also for Rudderstack. If we don't have one,
 * we should set one.
 *
 * @internal use {@link getEventMetadata}
 */
export const getDeviceID = (): string => {
  return DEVICE_ID_SCHEMA.parse(localStorage.getItem(LOCAL_STORAGE.DEVICE_ID))
}

/**
 * Stores the time for the latest onboarding so we can determine if we should
 * reset the onboarding state by checking if it was before midnight today.
 *
 * Use {@link setOnboarding}
 */
function setLatestOnboardingTime(): void {
  localStorage.setItem(
    LOCAL_STORAGE.LATEST_ONBOARDING_TIME,
    new Date().toISOString(),
  )
}

/**
 * Returns the time for the latest onboarding so we can determine if we should
 * reset the onboarding state by checking if it was before midnight today.
 *
 * @see {@link isOnboardedToday}
 */
export function getLatestOnboardingTime(): Date | null {
  const latestOnboardingTime = localStorage.getItem(
    LOCAL_STORAGE.LATEST_ONBOARDING_TIME,
  )

  if (!latestOnboardingTime) return null

  return new Date(latestOnboardingTime)
}

/**
 * Sets both the experience and the last onboarding time so we don't have
 * to call them both everywhere we do it risking forgetting one of them.
 */
export const setOnboarding = (experience: ChosenExperience): void => {
  setExperience(experience)
  setLatestOnboardingTime()
}

/**
 * Returns true if the user is onboarded today.
 */
export const isOnboardedToday = (): boolean => {
  const timestamp = getLatestOnboardingTime()

  return isToday(timestamp)
}

/**
 * Clears the Storage if last onboarding is before midnight today
 */
export const maybeResetOnboarding = (): void => {
  if (!isOnboardedToday()) {
    resetExperience()
    return
  }
}

type EstimatedLocation = {
  point?: IMDFPoint
  level?: ILevelRef
  building?: IVenueRef | { featureType: 'building'; uuid: string }
}

const ESTIMATED_LOCATION_SCHEMA = z.object({
  point: GeojsonPointSchema.optional(),
  level: LevelRefSchema.optional(),
  building: FeatureRefSchema.optional().refine(
    (value): value is FeatureRef & EstimatedLocation['building'] =>
      !value ||
      value.featureType === 'venue' ||
      value.featureType === 'building',
  ),
})

/**
 * Retrieve the estimated location.
 */
export const getEstimatedLocation = (): EstimatedLocation => {
  const location = localStorage.getItem(LOCAL_STORAGE.ESTIMATED_LOCATION)

  if (!location) return {}

  try {
    return ESTIMATED_LOCATION_SCHEMA.parse(JSON.parse(location))
  } catch (e) {
    debugError('Error parsing location', e)
  }

  return {}
}

/**
 * Set the estimated location in localstorage
 */
export const setEstimatedLocation = (
  location: EstimatedLocation | null,
): void => {
  if (!location) {
    debugLog('Clearing location')

    localStorage.removeItem(LOCAL_STORAGE.ESTIMATED_LOCATION)
    return
  }

  debugLog('Updating location', location)

  const { point, level, building } = location

  localStorage.setItem(
    LOCAL_STORAGE.ESTIMATED_LOCATION,
    JSON.stringify({
      point: point && new GeojsonPoint(point as IGeojsonPoint),
      level: level && new LevelRef(level),
      building: building && new FeatureRef(building),
    }),
  )
}

const AUDIENCE_SCHEMA = z.nativeEnum(Audience).catch(Audience.PUBLIC)

/**
 * Set the audience and force the app to reload
 */
export const setAudience = (audience: Audience): void => {
  // Update the local storage
  localStorage.setItem(LOCAL_STORAGE.AUDIENCE, audience)

  const url = new URL(window.location.href)

  // Remove any item from the search params
  url.searchParams.delete(SEARCH_PARAMS.AUDIENCE)
  window.location.replace(url.toString())
}

/**
 * Get the current audience
 */
export const getAudience = (): Audience => {
  const searchParams = new URLSearchParams(window.location.search)

  // See if we've passed in an audience, if we have reset ourselves to be
  // using that audience
  const maybeAudience = searchParams.get(SEARCH_PARAMS.AUDIENCE)

  if (maybeAudience) {
    const audience = AUDIENCE_SCHEMA.parse(maybeAudience)

    setAudience(audience)

    return audience
  }

  return AUDIENCE_SCHEMA.parse(localStorage.getItem(LOCAL_STORAGE.AUDIENCE))
}
