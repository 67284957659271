import { GazelleRef } from '@apsys/gazelle'
import type { Venue, Building, Level, Geofence, Unit } from '@apsys/imdf'

import { PWACatalogObject } from 'hooks/useCatalogObjectList'
import { PWASpace } from 'hooks/useSpace'

import type { AllFeatureTypes } from './imdf'

type Opts = {
  withCatalogObjectRef?: boolean
}

/**
 * For komodo analytics calls. "Contexts" describe the scenario
 * that you are in UI wise. eg. Object inside zone inside tour.
 *
 * @deprecated Komodo
 */
export const getContexts = (
  pwaEntity: PWACatalogObject | PWASpace,
  { withCatalogObjectRef = false }: Opts = {},
): Array<GazelleRef> => {
  // Type guard for experience-laden catalog objects
  const contexts: Array<GazelleRef> = []

  if ('feature' in pwaEntity) {
    if (pwaEntity.content) {
      contexts.push(pwaEntity.content.ref)
    }
  }

  if ('catalogObject' in pwaEntity) {
    if (withCatalogObjectRef) {
      contexts.push(pwaEntity.catalogObject.ref)
    }

    if (pwaEntity.source === 'immersive-tour') {
      contexts.push(pwaEntity.tour.ref)
      contexts.push(pwaEntity.scene.ref)
    }
  }

  contexts.push(pwaEntity.vxConfig.ref)

  return contexts
}

/**
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
 * @category Utils
 */
export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${JSON.stringify(x)}`)
}

/**
 * Ensures that the input is not null or undefined while maintaining the type safety.
 */
export const notNil = <T>(input: T | null | undefined): input is T => !!input

/** Typeguard to assert something is a building or venue */
export const isBuildingLike = (
  feature: AllFeatureTypes | undefined,
): feature is Venue | Building =>
  feature?.feature_type === 'venue' || feature?.feature_type === 'building'

/** Typeguard to assert something is a building */
export const isBuilding = (
  feature: AllFeatureTypes | undefined,
): feature is Building => feature?.feature_type === 'building'

/** Typeguard to assert something is a level */
export const isLevel = (
  feature: AllFeatureTypes | undefined,
): feature is Level => feature?.feature_type === 'level'

/** Typeguard to check the return type is a space */
export const isSpace = (
  feature: AllFeatureTypes | undefined,
): feature is Unit | Geofence =>
  feature?.feature_type === 'unit' || feature?.feature_type === 'geofence'
